<template>
  <div class="w-full px-4 my-8 md:px-0 md:w-1/2 md:mx-auto lg:w-1/3">
    <div  v-if="!linkSent">
      <h1 class="font-serif text-primary text-2xl pb-4">Forgot Password</h1>
      <p class="text-gray-600 text-base leading-normal">
        Not to worry! Enter your email below and we'll send you a link to create a new password.
      </p>
      <TextInput
        label="Email address"
        type="email"
        id="email"
        class="my-6"
        v-model="email"
        :invalid="!!authenticationError"
        @keyup.enter.native="sendForgotPasswordLink(email)"/>
      <ErrorBox
        v-if="authenticationError"
        :errorMessage="authenticationError"
      />
      <Button
        type="primary"
        @onClick="requestSendLink()"
        :disabled="buttonIsDisabled"
        :loading="isLoading"
      >
        Send Link
      </Button>
    </div>
    <div v-if="linkSent">
      <h1 class="font-serif text-primary text-2xl pb-4">Check your email</h1>
      <p class="text-gray-600 text-base leading-normal mb-6">
        We've sent an email to {{email}} with instructions to create a new password.
      </p>
      <Button
        type="text"
        @onClick="updateLinkSentStatus()"
      >
        Send Another Email
      </Button>
    </div>

    <div class="back" v-else>
      <router-link
        key="login"
        class="inline-block font-semibold leading-relaxed mt-4 relative text-left
          appearance-none rounded cursor-pointer transform underline text-primary"
        :to="{ name: 'login' }">
        <span>Back to login</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ForgotPassword',
  components: {
    ErrorBox,
    TextInput,
    Button,
  },
  computed: {
    ...mapGetters(['linkSent', 'isLoading', 'authenticationError']),
    buttonIsDisabled() {
      return !this.email.trim() || this.isLoading;
    },
  },
  data() {
    return {
      email: '',
    };
  },
  beforeDestroy() {
    // reset store
    this.updateLinkSentStatus(false);
    this.showAuthError(null);
  },
  methods: {
    ...mapActions([
      'sendForgotPasswordLink',
      'showAuthError',
      'updateLinkSentStatus',
    ]),
    requestSendLink() {
      if (!this.email.trim()) {
        return this.showAuthError('Please enter a valid email and password to login.');
      }
      return this.sendForgotPasswordLink(this.email);
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
