<template>
  <div class="flex items-center p-4 bg-red-100 rounded" :class="{ 'my-0': noMargin }">
    <div>
      <Error />
    </div>
    <p class="text-lg text-red-600 font-sans-serif pl-4 font-semibold">
      <template v-if="message && !message.fieldName">
        {{ message.message || message }}
      </template>
      <template v-if="message && message.fieldName">
        {{ fetchErrorFieldName(message.fieldName) }}: {{ message.message }}
      </template>
    </p>
  </div>
</template>

<script>
import Error from '@/assets/error.svg';

export default {
  name: 'ErrorBox',
  components: {
    Error,
  },
  props: {
    /**
     * Error message to be displayed
     */
    message: {
      type: [Object, String],
      default: 'Oops! Something went wrong.',
    },
    /**
     * remove margin styling when true
     */
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fetchErrorFieldName(name) {
      const fieldName =
        document.querySelector(`input[name="${name}"]`) ||
        document.querySelector(`textarea[name="${name}"]`);
      if (fieldName) return fieldName.parentElement.querySelector('label').textContent.trim();
      return name;
    },
  },
};
</script>
