<template>
  <div class="mx-auto pt-12 px-6 sm:px-12 lg:px-8 max-w-screen-xl">
    <Breadcrumbs class="mb-3" :crumbs="breadcrumbs" />
    <h1 class="text-gray-900 text-[26px] lg:text-[56px]">Create New Member</h1>
    <CreateMemberForm />
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import CreateMemberForm from '@/components/forms/CreateMemberForm.vue';

export default {
  name: 'CreateMember',
  components: { CreateMemberForm, Breadcrumbs },

  data() {
    return {
      breadcrumbs: [
        {
          label: 'Create New Member',
          link: 'create-member',
        },
      ],
    };
  },

  mounted() {
    if (this.$route.params && this.$route.params.slug === undefined) {
      this.$router.push({ name: 'groups' });
    }
  },
};
</script>
