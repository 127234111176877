<template>
  <div>
    <Toast v-if="error">{{ error }}</Toast>
    <div class="text-gray-900 font-normal">
      <h1 class="pb-4 text-[26px] md:text-[56px] leading-[140%]">Hey, {{ $route.query.name }}</h1>
      <p class="mb-1 text-base leading-[160%]">
        To start using your account you need to reset a password.
      </p>
      <p class="text-base leading-[160%]">
        Using this information you will be able to log in to our system!
      </p>
    </div>
    <div class="mb-4 md:mb-8 pt-8">
      <div class="max-w-[400px] pr-0 md:pr-6">
        <TextInput
          id="email"
          label="Your Email"
          placeholder="Email"
          type="email"
          v-model="$route.query.email"
          disabled
        />
      </div>
    </div>
    <div class="flex items-center flex-wrap md:flex-nowrap gap-6 mb-4 md:mb-8">
      <div class="relative w-full max-w-[400px]">
        <TextInput
          label="Create new Password"
          type="password"
          placeholder="Enter Password"
          id="password"
          @blur="$v.password.$touch()"
          v-model="password"
        />
        <span v-if="$v.password.$dirty && !$v.password.required" class="error-message">
          This field is required.
        </span>
      </div>
      <div class="relative w-full max-w-[400px]">
        <TextInput
          label="Confirm new Password"
          placeholder="Confirm Password"
          type="password"
          id="confirmPassword"
          class="max-w-[400px]"
          @blur="$v.confirmPassword.$touch()"
          v-model="confirmPassword"
        />
        <span
          v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
          class="error-message"
        >
          This field is required.
        </span>
        <span
          v-else-if="$v.confirmPassword.$dirty && !$v.confirmPassword.sameAsPassword"
          class="error-message"
        >
          Passwords don't match
        </span>
      </div>
    </div>

    <div>
      <div class="pt-8 border-t border-gray-300">
        <Checkbox
          id="show_email"
          :large-only="true"
          compact
          v-model="isShowEmail"
          label="Display email on profile"
        />
        <Checkbox
          id="receive_emails"
          class="mt-4"
          :large-only="true"
          compact
          v-model="isReceiveAdminEmails"
          label="Subscribe to group email updates"
        />
      </div>

      <div class="mt-4 mb-4 md:mb-8 pt-4 md:pt-8">
        <p class="text-gray-600">
          By creating an account, you agree to our
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="text-blue-400 underline"
            href="https://reunacy.com/terms-of-use/"
          >
            Terms of Use</a
          >
          and
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="text-blue-400 underline"
            href="https://reunacy.com/privacy-policy/"
          >
            Privacy Policy</a
          >.
        </p>
      </div>

      <div class="flex gap-2 mb-10 mt-4">
        <Button
          type="primary"
          :primary-full-size="false"
          @onClick="saveTransfer"
          class="h-[40px] lg:h-[56px] min-w-[120px] lg:min-w-[150px]"
        >
          Save
        </Button>
        <Button
          type="text"
          class="h-[40px] lg:h-[56px] min-w-[120px] lg:min-w-[150px]"
          @onClick="cancel"
        >
          Cancel
        </Button>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        class="left-0"
        v-if="isShowSuccessModal"
        header="Success"
        @cancel="hideSuccessModal"
        :is-show-close-btn="false"
        :is-show-control-buttons="false"
        scheme="secondary"
        container-class="sm:w-[440px]"
      >
        <div>
          <p class="mt-4 mb-8 text-base font-normal leading-[160%]">
            You have successfully updated your password. Use your email address and password to log
            into your account.
          </p>
          <div class="flex justify-center">
            <Button
              class="max-w-[220px] mt-3"
              type="primary"
              @onClick="$router.push({ name: 'login' })"
            >
              Go to Log In page
            </Button>
          </div>
        </div>
      </DialogDefault>
    </transition>
  </div>
</template>
<script>
import Toast from '@/components/Toast.vue';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import TextInput from '@/components/TextInput.vue';
import { required, sameAs } from 'vuelidate/lib/validators';
import { transferResetPasswordService } from '@/services/global.service';
import DialogDefault from '@/components/DialogDefault.vue';

export default {
  name: 'TransferResetPassword',

  components: {
    DialogDefault,
    Toast,
    TextInput,
    Button,
    Checkbox,
  },

  data() {
    return {
      error: '',
      password: '',
      confirmPassword: '',
      isShowEmail: false,
      isReceiveAdminEmails: false,
      isShowSuccessModal: false,
    };
  },

  validations() {
    return {
      password: { required },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(function () {
          return this.password;
        }),
      },
    };
  },

  methods: {
    hideSuccessModal() {
      this.isShowSuccessModal = false;
    },
    showSuccessModal() {
      this.isShowSuccessModal = true;
    },
    async saveTransfer() {
      const { uid, token, email } = this.$route.query;
      const payload = {
        receiveAdminEmails: this.isReceiveAdminEmails,
        showEmail: this.isShowEmail,
        email,
        newPassword1: this.password,
        newPassword2: this.confirmPassword,
        uid,
        token,
      };

      try {
        await transferResetPasswordService(payload);
        this.showSuccessModal();
      } catch (error) {
        this.error = 'Oops! Something went wrong. Please refresh the page and try again.';
      }
    },
    cancel() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
