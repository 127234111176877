<template>
  <div class="flex space-between flex-col md:flex-row-reverse gap-6 pt-10">
    <Toast v-if="error">{{ error }}</Toast>

    <div class="flex w-full md:w-1/3">
      <div class="relative w-full">
        <PhotoUploadInlineForm
          :existingImage="member.profilePicture"
          @submitted="handlePhotoUpload"
        />
      </div>
    </div>

    <div class="flex flex-col w-full md:w-2/3">
      <div class="flex flex-col lg:flex-row mb-6 gap-4">
        <div class="mb-8 lg:mb-0 w-full lg:w-1/2 relative">
          <TextInput
            id="firstName"
            label="Enter First Name"
            @blur="$v.member.firstName.$touch()"
            v-model="member.firstName"
          />
          <span
            v-if="$v.member.firstName.$dirty && !$v.member.firstName.required"
            class="error-message"
          >
            This field is required.
          </span>
        </div>

        <div class="w-full lg:w-1/2 relative">
          <TextInput
            id="lastName"
            label="Enter Last Name"
            @blur="$v.member.lastName.$touch()"
            v-model="member.lastName"
          />

          <span
            v-if="$v.member.lastName.$dirty && !$v.member.lastName.required"
            class="error-message"
          >
            This field is required.
          </span>
        </div>
      </div>

      <div class="mb-8 pt-8 border-t border-gray-300 relative">
        <MapsAutocompleteInput
          id="city"
          :search-types="'(cities)'"
          label="Where do you live?"
          placeholder="Current city"
          :value="member.city"
          name="city"
          @updateCity="handleUpdateCity"
          @updateState="handleUpdateState"
          @updateCounty="handleUpdateCountry"
        />
      </div>

      <div class="mb-8">
        <TextInput
          id="school"
          label="Which school(s) did / do you attend?"
          placeholder="(e.g. Georgetown University)"
          :maxLength="70"
          v-model="member.school"
        />
      </div>

      <div class="mb-8">
        <TextInput
          id="major"
          label="What did / do you study?"
          placeholder="Major (e.g. Psychology)"
          :maxLength="70"
          v-model="member.major"
        />
      </div>

      <div class="mb-8">
        <TextInput
          id="work"
          label="Where do you work?"
          placeholder="Work (e.g. Intern at Lunacy Productions)"
          :maxLength="70"
          v-model="member.employer"
        />
      </div>

      <div class="mb-8">
        <p class="text-sm font-semibold text-gray-800 mb-2">
          Write a brief bio about yourself.
          <span class="hidden sm:block text-gray-800">
            Talk about what you have been up to lately
          </span>
        </p>
        <VueEditor
          :customModules="customModulesForEditor"
          :editorOptions="editorSettings"
          ref="bio"
          @image-added="handleImageAdded"
          class="bg-white shadow text-lg text-gray-900 font-sans rounded w-full lg:w-full"
          v-model="member.bio"
          placeholder="Write a bio"
          :editorToolbar="customToolbar"
        />
      </div>

      <div class="mb-8">
        <p class="text-gray-800 text-sm font-semibold mb-2">This person is deceased</p>
        <p class="flex gap-2 items-center">
          <span
            class="text-sm"
            :class="
              !member.isDeceased
                ? 'text-gray-900 font-semibold'
                : 'text-gray-800 font-normal opacity-80'
            "
          >
            No
          </span>
          <toggle-button
            v-model="member.isDeceased"
            :width="46"
            :height="26"
            :color="toggleColor"
          />
          <span
            class="text-sm"
            :class="
              member.isDeceased
                ? 'text-gray-900 font-semibold'
                : 'text-gray-800 font-normal opacity-80'
            "
          >
            Yes
          </span>
        </p>
      </div>

      <!-- If you need to add the date of death in the future, remove it "false" -->
      <div v-if="false" class="relative mb-8 w-1/2">
        <p class="text-sm font-semibold text-gray-800 mb-2">Date of death</p>
        <CalendarInput
          id="nextEventDate"
          label="Select date"
          focusedPlaceholder="MM.DD.YYYY"
          :readonly="true"
          :showLabel="true"
          v-model="member.dateOfDeath"
          :localStringFormat="timeLocalFormat"
          :date-restrict-function="disableFutureDates"
        />
        <span
          v-if="$v.member.dateOfDeath.$dirty && !$v.member.dateOfDeath.required"
          class="error-message"
        >
          This field is required.
        </span>
      </div>

      <div
        v-if="!member.isDeceased"
        class="mb-9 pt-4 sm:mb-11 border-b border-t border-gray-300 pb-4 sm:pb-0"
      >
        <h4 class="font-bold text-xl mb-6">Social media</h4>

        <div class="media-container flex flex-col sm:flex-row mb-6">
          <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
            Instagram
          </div>
          <TextInput
            :id="'social-media-instagram'"
            placeholder="@username or URL"
            v-model="member.instagram.url"
          />
        </div>

        <div class="media-container flex flex-col sm:flex-row mb-6">
          <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
            Facebook
          </div>
          <TextInput
            :id="'social-media-facebook'"
            placeholder="URL"
            v-model="member.facebook.url"
          />
        </div>

        <div class="media-container flex flex-col sm:flex-row mb-6">
          <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
            LinkedIn
          </div>
          <TextInput
            :id="'social-media-linkedin'"
            placeholder="URL"
            v-model="member.linkedin.url"
          />
        </div>

        <div class="media-container flex flex-col sm:flex-row mb-6">
          <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
            Twitter
          </div>
          <TextInput
            :id="'social-media-twitter'"
            placeholder="@username or URL"
            v-model="member.twitter.url"
          />
        </div>
      </div>

      <div v-if="!member.isDeceased" class="mb-8">
        <TextInput
          id="link"
          label="Add a link to the Member’s website"
          placeholder="Website"
          v-model="member.link"
          class="sm:mr-2"
        />
      </div>

      <div v-if="!member.isDeceased" class="mb-8 relative">
        <TextInput
          mask="(###)###-####"
          id="Phone"
          label="Phone"
          type="tel"
          placeholder="(555)555-5555"
          v-model="member.phoneNumber"
          class="sm:mr-2"
          error-message="Invalid Phone Number"
        />
      </div>

      <div class="border-t border-gray-300 mt-4 mb-4 pt-8">
        <p class="text-gray-600">
          By creating an account, you agree to our
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="text-blue-400 underline"
            href="https://reunacy.com/terms-of-use/"
          >
            Terms of Use</a
          >
          and
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="text-blue-400 underline"
            href="https://reunacy.com/privacy-policy/"
          >
            Privacy Policy</a
          >.
        </p>
      </div>

      <div class="flex gap-2 mb-10 mt-4">
        <Button
          type="primary"
          :primary-full-size="false"
          @onClick="create"
          class="h-[40px] lg:h-[56px] min-w-[120px] lg:min-w-[150px]"
        >
          Create
        </Button>
        <Button
          type="text"
          class="h-[40px] lg:h-[56px] min-w-[120px] lg:min-w-[150px]"
          @onClick="cancel"
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import PhotoUploadInlineForm from '@/components/PhotoUploadInlineForm.vue';
import TextInput from '@/components/TextInput.vue';
import MapsAutocompleteInput from '@/components/MapsAutocompleteInput.vue';
import ImageResize from 'quill-image-resize-module';
import { VueEditor } from 'vue2-editor';
import Button from '@/components/Button.vue';
import { DEFAULT_INTERACTIVE_COLOR } from '@/components/constants/groupColors';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Toast from '@/components/Toast.vue';
import CalendarInput from '@/components/CalendarInput.vue';

export default {
  name: 'CreateMemberForm',
  components: {
    CalendarInput,
    Toast,
    Button,
    MapsAutocompleteInput,
    TextInput,
    PhotoUploadInlineForm,
    VueEditor,
  },

  data() {
    return {
      member: {
        profilePicture: '',
        isDeceased: false,
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        country: '',
        school: '',
        employer: '',
        major: '',
        bio: '',
        instagram: {
          media_type: 1,
          url: '',
        },
        facebook: {
          media_type: 2,
          url: '',
        },
        linkedin: {
          media_type: 3,
          url: '',
        },
        twitter: {
          media_type: 4,
          url: '',
        },
        phoneNumber: '',
        link: '',
        dateOfDeath: null,
      },
      defaultButtonColor: DEFAULT_INTERACTIVE_COLOR,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
      ],
      customModulesForEditor: [
        {
          alias: 'imageResize',
          module: ImageResize,
        },
      ],
      editorSettings: {
        modules: {
          imageResize: {
            modules: ['Resize'],
          },
        },
      },
      error: '',
    };
  },

  computed: {
    toggleColor() {
      return {
        checked: `rgba(${this.getButtonDefaultColor()})`,
        unchecked: '#AAACAF',
        disabled: '#CCCCCC',
      };
    },
    timeLocalFormat() {
      return 'MM.dd.yyyy';
    },
  },

  validations() {
    return {
      member: {
        firstName: { required },
        lastName: { required },

        // If you need to add the date of death in the future, remove comments for validation

        // dateOfDeath: this.member.isDeceased ? { required } : {},
      },
    };
  },

  methods: {
    ...mapActions(['createNewMember']),

    disableFutureDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },

    getButtonDefaultColor() {
      return this.defaultButtonColor;
    },

    handlePhotoUpload(blob) {
      this.member.profilePicture = blob;
    },

    handleUpdateCity(city) {
      this.member.city = city;
    },

    handleUpdateState(state) {
      this.member.state = state;
    },

    handleUpdateCountry(country) {
      this.member.country = country;
    },

    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.error = null;
      const formData = new FormData();
      formData.append('file', file);

      try {
        const result = await this.uploadGroupFile({
          groupSlug: this.$route.params.group,
          file: formData,
        });
        Editor.insertEmbed(cursorLocation, 'image', result);
        resetUploader();
      } catch (e) {
        this.error = 'Error: Your image could not be inserted. Please try again.';
      }
    },

    async create() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.createNewMember({ newMember: this.member, slug: this.$route.params.slug });
        this.$router.push({
          name: 'members',
          params: {
            group: this.$route.params.slug,
          },
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    cancel() {
      this.$router.push({
        name: 'members',
        params: {
          group: this.$route.params.slug,
        },
      });
    },
  },
};
</script>
