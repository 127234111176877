<template>
  <div
    class="w-full px-4 my-8 md:px-0 md:mx-auto"
    :class="{
      'md:w-1/2 lg:w-1/3': !isProfileTransfer,
      'px-6 sm:px-12 lg:px-8 max-w-screen-md': isProfileTransfer,
    }"
  >
    <template v-if="isProfileTransfer">
      <TransferResetPassword />
    </template>
    <template v-else>
      <div v-if="!passwordResetConfirmed">
        <h1 class="font-serif text-primary text-2xl pb-4">Create a new password</h1>
        <p class="text-gray-600 text-base leading-normal">
          Passwords must be at least 8 characters and contain at least one special, non-letter
          character.
        </p>
        <TextInput
          label="New password"
          type="password"
          id="password"
          class="my-6"
          v-model="password"
          :invalid="!!authenticationError"
          @keyup.enter.native="requestResetPassword(password)"
        />
        <Button
          type="primary"
          @onClick="requestResetPassword()"
          :disabled="buttonIsDisabled"
          :loading="isLoading"
        >
          Reset Password
        </Button>
        <ErrorBox v-if="authenticationError" :errorMessage="authenticationError" />
      </div>
      <div v-if="passwordResetConfirmed">
        <h1 class="font-serif text-primary text-2xl pb-4">Your password has been reset</h1>
        <p class="text-gray-600 text-base leading-normal mb-6">
          You’ve successfully changed your password! You can now login with your new password.
        </p>
        <Button type="primary" @onClick="navigateToLogin()"> Return to Login </Button>
      </div>
    </template>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { mapActions, mapGetters } from 'vuex';
import TransferResetPassword from '@/components/forms/TransferResetPassword.vue';

export default {
  name: 'ForgotPassword',
  components: {
    TransferResetPassword,
    ErrorBox,
    TextInput,
    Button,
  },
  computed: {
    ...mapGetters(['passwordResetConfirmed', 'isLoading', 'authenticationError']),
    buttonIsDisabled() {
      return !this.password.trim() || this.isLoading;
    },
    isProfileTransfer() {
      return !!this.$route.query.is_profile_transfer;
    },
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  beforeDestroy() {
    // reset store
    this.updatePasswordResetStatus(false);
    this.updateLinkSentStatus(false);
    this.showAuthError(null);
  },

  methods: {
    ...mapActions([
      'resetPassword',
      'showAuthError',
      'updatePasswordResetStatus',
      'updateLinkSentStatus',
    ]),
    requestResetPassword() {
      const { uid, token } = this.$route.query;
      if (!this.password.trim()) {
        return this.showAuthError(true);
      }
      return this.resetPassword({
        pw: this.password,
        uid,
        token,
      });
    },
    navigateToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
